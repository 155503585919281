<template>
  <div class="bodydonate">
    <div class="body-container">
      <Remembrance :remembrancelist="remembrancelist"></Remembrance>
    </div>
  </div>
</template>

<script>
import Remembrance from './components/remembrance'
import apiUrls from '@api'
export default {
  components: {
    Remembrance,
  },
  name: 'Bodydonate',
  data () {
    return {
      remembrancelist : [],
    }
  },
  created () {
    this.Remembrance()
  },
  methods: {
    Remembrance() {
      apiUrls.getbodydonate({node: 'zgxb_wcxx'}).then(res=>{
      this.remembrancelist= res.results.data
      })
    },
  }
}
</script>

<style lang="less" scoped>
.bodydonate {
  background-color: #f5f5f5;
  .body-container {
    width: 1200px;
    min-height: 400px;
    padding-bottom: 40px;
    margin: 0 auto;
    background-color: #f5f5f5;

    &::before {
        display: block;
        content: ".";
        clear: both;
        height: 0;
        overflow: hidden;
        visibility: hidden; 
    }
  }
}
</style>
